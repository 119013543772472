<template>
  <div class="recipe" v-loading="loading">
    <!-- 顶部面包屑 -->
    <div class="top_breadcrumb">
      <span @click="$router.push('/drugs?type=second')">
        <i class="el-icon-arrow-left"></i>{{ recipesType }}
      </span>
      <span><i class="el-icon-arrow-left"></i>{{ recipe }}</span>
    </div>

    <!-- 主要内容 -->
    <div class="main">
      <!-- 左侧 -->
      <div class="left_tab">
        <el-menu @open="getCategoryId($event)">
          <template v-for="(item, index) in firstMenu">
            <el-submenu :key="item.id" :index="`${index}`">
              <template slot="title">
                <i class="el-submenu__icon-arrow el-icon-caret-right"></i>
                <span>{{ item.category_name }}</span>
              </template>
              <el-menu-item-group>
                <el-menu-item
                  v-for="(it, indx) in item.children"
                  :key="it.id"
                  :index="`${index + '-' + indx}`"
                  :class="{
                    'is-active': index + '-' + indx == activeIndex,
                  }"
                  @click="
                    Highlight(index + '-' + indx), getDrugsList(index, indx, 1)
                  "
                  >{{ it.category_name }}</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>
          </template>
        </el-menu>
      </div>

      <!-- 右侧 -->
      <div class="right_recipes">
        <div v-if="AList.length" v-loading="AListLoading">
          <div class="item" v-for="(item, index) in AList" :key="index">
            <h4 class="type">{{ item.index_category }}</h4>
            <ul>
              <li
                @click="
                  goList(
                    it.common_name,
                    it.drug_type1,
                    it.drug_type2,
                    it.drug_type3
                  )
                "
                v-for="it in item.children"
                :key="it.id"
              >
                <h4>{{ it.common_name }} ({{ it.sum }})</h4>
                <p>{{ it.group_info }}</p>
              </li>
            </ul>
          </div>
        </div>

        <!-- 暂无数据 -->
        <div v-else class="null-box">
          <img
            :src="$http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png'"
            alt=""
            srcset=""
          />
          <div class="title">暂无数据</div>
        </div>

        <!-- 分页 -->
        <el-pagination
          hide-on-single-page
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          :page-size="page_size"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      AListLoading: true,
      activeIndex: "",
      recipesType: "中药",
      recipe: "方剂",
      recipeList: [], // 方剂类型
      recipeConfigList: [],
      firstMenu: [], // 一级方剂
      recipesArray: [], //  方剂列表
      AList: [],
      firstIndex: 0, // 展开一级药品的index
      first_parent_id: "", // 一级药品id
      first_category_id: "", // 二级药品id
      loading: true,
      index1: "",
      index2: "",
      flag: false,
      page: 1,
      page_size: 50,
      total: 50,
    };
  },

  watch: {
    // 监听展开方剂一级药品的index
    firstIndex() {
      // 获取方剂分类
      this.recipeMenu2();
    },
  },

  async created() {
    await this.getRecipeId();
    await this.recipeMenu1();
    await this.getAllDrugsList(1);
  },

  methods: {
    // 获取一级药品id
    getCategoryId(index) {
      this.category_id = this.firstMenu[index].id;
      this.firstIndex = index;
    },

    // 获取方剂id
    async getRecipeId() {
      let res = await this.$findApi.drugMenu({
        parent_id: "-2",
      });
      if (res.code == 200) {
        this.recipeList = res.data;
        this.first_parent_id = res.data[0].id;
      } else {
        this.$message.error(res.message);
      }
    },

    // 获取一级方剂
    async recipeMenu1() {
      let res = await this.$findApi.drugMenu({
        parent_id: this.first_parent_id,
      });
      if (res.code == 200) {
        this.firstMenu = res.data;
        this.first_category_id = res.data[0].id;
      } else {
        this.$message.error(res.message);
      }
    },
    // 获取二级方剂
    async recipeMenu2() {
      let res = await this.$findApi.drugMenu({
        parent_id: this.category_id,
      });
      if (res.code == 200) {
        this.$set(this.firstMenu[this.firstIndex], "children", res.data);
      } else {
        this.$message.error(res.message);
      }
    },

    // 默认获取所有方剂列表
    async getAllDrugsList(page) {
      // 初始化
      this.recipeConfigList = [];
      this.recipeConfigList.push(this.recipeList[0]);
      this.categoryList(this.recipeConfigList, page);
    },

    // 获取方剂列表
    async getDrugsList(index1, index2, page) {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      // 初始化
      this.index1 = index1;
      this.index2 = index2;
      this.flag = true;
      this.page = 1;
      this.recipeConfigList = [];
      this.recipeConfigList.push(
        this.recipeList[0],
        this.firstMenu[index1],
        this.firstMenu[index1].children[index2]
      );
      this.categoryList(this.recipeConfigList, page);
      this.page = page;
    },

    // 分页
    handleCurrentChange(page) {
      this.AListLoading = true;
      this.page = page;
      if (this.flag) {
        this.getDrugsList(this.index1, this.index2, page);
      } else {
        this.getAllDrugsList(page);
      }
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },

    // 高亮
    Highlight(index) {
      this.activeIndex = index;
    },

    // 进入列表页
    goList(name, type1, type2, type3) {
      localStorage.setItem("common_name", name);
      localStorage.setItem(
        "recipeConfigList",
        JSON.stringify(this.recipeConfigList)
      );
      this.$router.push(
        "/recipeList?type1=" + type1 + "&type2=" + type2 + "&type3=" + type3
      );
    },
    /************************************* 公共方法 ***********************************************/
    async categoryList(drugConfigList, page) {
      let res = await this.$findApi.categoryList({
        token: this.token,
        drugConfigList,
        page,
      });
      this.AListLoading = false;
      this.loading = false;
      if (res.code == 200) {
        this.recipesArray = res.data.list;
        this.page_size = res.data.pageSize;
        this.total = res.data.total;
        // 包装AList[{}]
        this.AList = [];
        this.recipesArray.forEach((item) => {
          let typeA = item.index_category;
          if (this.AList.indexOf(typeA) < 0) {
            this.AList.push(typeA);
          }
        });
        this.AList = this.AList.map((item) => {
          return (item = { index_category: item, children: [] });
        });
        this.recipesArray.forEach((item) => {
          this.AList.forEach((it) => {
            if (item.index_category == it.index_category) {
              it.children.push(item);
            }
          });
        });
      } else {
        this.$message.error(res.message);
      }
    },
    /************************************* 公共方法end ***********************************************/
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

.recipe {
  background: #f2f2f2;
  // 顶部面包屑
  .top_breadcrumb {
    width: 1200px;
    margin: 0 auto;
    padding: 20px 0;

    span {
      margin-right: 10px;
      color: #717171;
      cursor: pointer;

      &:last-child {
        color: $--themeColor;
      }

      i {
        margin-right: 5px;
        font-weight: 700;
        color: #717171;
      }
    }
  }

  // 主要内容
  .main {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    min-height: 300px;
    margin-bottom: 50px;

    // 左侧tab栏
    .left_tab {
      width: 240px;
      border-radius: 5px 5px 0 0;
      overflow: hidden;
      border: 1px solid #d9d9d9;
      background: #fff;

      /deep/ {
        .el-tabs--card > .el-tabs__header .el-tabs__nav {
          width: 240px;
          border: none;
          background: #beeae4;
        }

        .el-tabs .el-tabs__header {
          margin-bottom: 0;
        }

        .el-tabs__item {
          width: 120px;
          height: 40px;
          line-height: 40px;
          padding: 0;
          text-align: center;
          color: #fff;
          border: none;
          border-bottom: 2px solid $--themeColor;
        }
        .el-tabs .el-tabs__item.is-active {
          transform: scale(1);
          background: $--themeColor;
          border-radius: 0 5px 0 0;
        }

        .el-tabs__content {
          border: 1px solid #d9d9d9;
          border-top: none;
        }

        .el-menu {
          border-right: none;
          .el-submenu {
            border: none;

            .el-menu-item-group__title {
              display: none;
            }

            .el-submenu__title {
              height: 48px;
              line-height: 48px;
              border: none;
              padding-left: 30px !important;
              font-size: 16px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 100%;
              display: block;

              &:hover {
                background: #fff;
              }
            }

            .el-menu-item {
              height: 40px;
              line-height: 40px;
              background: #fff;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;

              &:hover,
              &:active,
              &:visited {
                background: #fff;
              }
            }

            .el-submenu__icon-arrow {
              left: 10px;
              font-size: 18px;
              color: #333;
              width: 18px;
              height: 18px;
            }

            .el-icon-arrow-down {
              display: none;
            }
          }

          .el-submenu.is-opened {
            .el-submenu__title {
              color: $--themeColor;
            }
            .el-submenu__title .el-submenu__icon-arrow {
              transform: rotate(90deg);
              color: $--themeColor;
            }
          }
        }
      }
    }

    // 右侧药品
    .right_recipes {
      margin: 0 0 0 20px;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      padding: 10px 30px;
      flex: 1;

      .item {
        .type {
          border-bottom: 1px solid #d9d9d9;
          padding: 10px 0;
          font-size: 16px;
        }
        ul {
          li {
            border-bottom: 1px solid #d9d9d9;
            padding: 10px 0;
            cursor: pointer;
            h4 {
              margin-bottom: 5px;
              font-size: 16px;
            }
            p {
              font-size: 14px;
              color: #8e8e8e;
            }
          }
        }
      }

      .el-pagination {
        text-align: center;
        margin-top: 20px;
      }
    }
  }
}
</style>