var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"recipe"},[_c('div',{staticClass:"top_breadcrumb"},[_c('span',{on:{"click":function($event){return _vm.$router.push('/drugs?type=second')}}},[_c('i',{staticClass:"el-icon-arrow-left"}),_vm._v(_vm._s(_vm.recipesType)+" ")]),_c('span',[_c('i',{staticClass:"el-icon-arrow-left"}),_vm._v(_vm._s(_vm.recipe))])]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"left_tab"},[_c('el-menu',{on:{"open":function($event){return _vm.getCategoryId($event)}}},[_vm._l((_vm.firstMenu),function(item,index){return [_c('el-submenu',{key:item.id,attrs:{"index":`${index}`}},[_c('template',{slot:"title"},[_c('i',{staticClass:"el-submenu__icon-arrow el-icon-caret-right"}),_c('span',[_vm._v(_vm._s(item.category_name))])]),_c('el-menu-item-group',_vm._l((item.children),function(it,indx){return _c('el-menu-item',{key:it.id,class:{
                  'is-active': index + '-' + indx == _vm.activeIndex,
                },attrs:{"index":`${index + '-' + indx}`},on:{"click":function($event){_vm.Highlight(index + '-' + indx), _vm.getDrugsList(index, indx, 1)}}},[_vm._v(_vm._s(it.category_name))])}),1)],2)]})],2)],1),_c('div',{staticClass:"right_recipes"},[(_vm.AList.length)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.AListLoading),expression:"AListLoading"}]},_vm._l((_vm.AList),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('h4',{staticClass:"type"},[_vm._v(_vm._s(item.index_category))]),_c('ul',_vm._l((item.children),function(it){return _c('li',{key:it.id,on:{"click":function($event){return _vm.goList(
                  it.common_name,
                  it.drug_type1,
                  it.drug_type2,
                  it.drug_type3
                )}}},[_c('h4',[_vm._v(_vm._s(it.common_name)+" ("+_vm._s(it.sum)+")")]),_c('p',[_vm._v(_vm._s(it.group_info))])])}),0)])}),0):_c('div',{staticClass:"null-box"},[_c('img',{attrs:{"src":_vm.$http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png',"alt":"","srcset":""}}),_c('div',{staticClass:"title"},[_vm._v("暂无数据")])]),_c('el-pagination',{attrs:{"hide-on-single-page":"","current-page":_vm.page,"page-size":_vm.page_size,"layout":"prev, pager, next, jumper","total":_vm.total},on:{"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){_vm.page=$event},"update:current-page":function($event){_vm.page=$event}}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }